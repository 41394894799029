import "./header.css";
function Header(params) {
	return (
		<div>
			<h3 className="head">From Simple to Complex: A Design Odyssey</h3>
		</div>
	);
}

export default Header;
