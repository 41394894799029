import "./App.css";
import Basic from "./component/Basic";
function App() {
	return (
		<div className="App">
			<Basic />
		</div>
	);
}

export default App;
